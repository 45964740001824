// extracted by mini-css-extract-plugin
export var bg = "features-module--bg--720eK";
export var content = "features-module--content--6SNaf";
export var heading = "features-module--heading--URJnw";
export var subheading = "features-module--subheading--cZfOs";
export var featureContainer = "features-module--featureContainer--CHVk5";
export var features = "features-module--features--u-qiA";
export var feature = "features-module--feature--+T8sV";
export var pic = "features-module--pic--lrybs";
export var title = "features-module--title--uO6OK";
export var subtitle = "features-module--subtitle--F87Mo";