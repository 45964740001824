import React from 'react';
import * as styles from './two-column-image-small.module.scss';
import Button from "../../common/button";
import RichText from "../../common/rich-text";
import { v4 as uuidv4 } from 'uuid';
import Video from "../../common/video";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import {PopupButton} from "@typeform/embed-react";
import * as buttonStyles from "../../common/button/button.module.scss";

const TwoColumnImageSmall = ({ slice }) => {
  const primaryData = slice.primary;
  const items = slice.items;

  const { width } = useWindowSize();

  return (
    <div style={{backgroundColor: 'black', width: '100%', padding: '82px 0'}}>
      <div className={styles.container}>
        <div
          className={`${styles.imageContainer} ${primaryData.image_position === 'Right' ? styles.gridImageRight : styles.gridImageLeft}`}
        >
          {primaryData.image.url ? (
              <img
                src={width > 576 ? primaryData.image.url : primaryData.image_mobile.url ? primaryData.image_mobile.url : primaryData.image.url}
                alt={'section'}
              />
            )
            : primaryData.video_url.url && (
            <Video
              url={ primaryData.video_url.url}
              url_mobile={primaryData.video_mobile_url.url}
              autoPlay={primaryData.video_autoplay}
              muted={primaryData.video_muted}
              loop={primaryData.video_loop}
              playsInline={primaryData.video_playsinline}
              controls={primaryData.video_controls}
            />
          )}
        </div>
        <div
          className={`${styles.contentBlock} ${primaryData.image_position === 'Right' ? styles.gridContentLeft : styles.gridContentRight}`}
        >
          <div className={styles.contentWrapper}>
            <h4 className={styles.heading}>
              {primaryData.heading.text}
            </h4>
            <h5 className={styles.subheading}>
              <RichText>{primaryData.subheading.html}</RichText>
            </h5>
            <div className={styles.content}>
              <RichText>{primaryData.content.html}</RichText>
            </div>
            <div className={styles.buttons}>
              {items?.map(item =>
                item.cta_link.url && item.cta_link.url.includes('typeform.com') ? (
                  <PopupButton id={item.cta_link.url.substring(item.cta_link.url.lastIndexOf('/') + 1)} className={`${buttonStyles.button}`} key={uuidv4()}>
                    {items.cta_title}
                  </PopupButton>
                ) : (
                  <a href={item.cta_link.url} key={uuidv4()}>
                    <Button>{item.cta_title}</Button>
                  </a>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};

export default TwoColumnImageSmall;