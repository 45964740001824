import React, { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import { v4 } from 'uuid';
import 'swiper/css';
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import * as styles from './slider.module.scss';



const Slider = ({ slice }) => {
  const items = slice.items;

  const { width } = useWindowSize();
  const [active, setActive] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState(null)

  return (
    <div className={styles.wrapper}>
      <div className={styles.slideContainer}>
        {items.map((item, i) => (
          <div className={`${styles.slide} ${active === i ? styles.active : ''}`} key={v4()}>
            {item.hasOwnProperty('image') && (
              <div>
                <img src={item.image.url} alt={'slider'} />
              </div>
            )}
            {item.hasOwnProperty('video_url') && (
              <div>
                <video
                  autoPlay
                  muted
                  src={item.video_url.url}
                />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className={styles.tabContainer}>
        {width > 576 ? (
          items.map((item, i) => (
            <div
              className={`${styles.tab} unselectable ${active === i ? styles.activeTab : ''}`}
              onClick={() => setActive(i)}
              key={v4()}
            >
              <h3 className={styles.title}>
                {item.title1.text}
              </h3>
              <p className={styles.description}>
                {item.description.text}
              </p>
            </div>
          ))
        ) : (
          <Swiper
            spaceBetween={25}
            centeredSlides={true}
            slidesPerView={1.4}
            onSlideChange={({activeIndex}) => setActive(activeIndex)}
            onSwiper={(swiper) => setSwiperInstance(swiper)}
          >
            {items.map((item, i) => (
              <SwiperSlide key={v4()}>
                <div
                  className={`${styles.tab} unselectable ${active === i ? styles.activeTab : ''}`}
                  onClick={() => {
                    setActive(i);
                    swiperInstance.slideTo(i);
                  }}
                  key={v4()}
                >
                  <h3 className={styles.title}>
                    {item.title1.text}
                  </h3>
                  <p className={styles.description}>
                    {item.description.text}
                  </p>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
};

export default Slider;