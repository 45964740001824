import React, { useRef } from 'react';
import {PopupButton} from "@typeform/embed-react";
import * as styles from './two-column-image.module.scss';
import * as buttonStyles from '../../common/button/button.module.scss';
import Button from "../../common/button";
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import RichText from "../../common/rich-text";
import { v4 as uuidv4 } from 'uuid';
import Font from "../font";
import Video from "../../common/video";


const TwoColumnImage = ({ slice }) => {
  const primaryData = slice.primary;
  const items = slice.items;

  const { width, height } = useWindowSize();

  const contentBlock = useRef(null);
  const container= useRef(null);

  return (
    <div
      ref={container}
      className={`two-column-image ${styles.container}`}
      style={width > 768 ? { height: height, minHeight: `auto` } : { minHeight: height }}
    >
      <div
        ref={contentBlock}
        className={styles.contentBlock}
        style={{order: primaryData.image_position === 'Right' ? 1 : 2}}
      >
        <div className={styles.contentWrapper}>
          <h3 className={styles.subheading}>
            <RichText>{primaryData.subheading.html}</RichText>
          </h3>
          <h2 className={styles.heading}>
            {primaryData.heading.text}
          </h2>
          {(width > 1200 || width <= 768) && (
            <div className={styles.content}>
              <RichText>{primaryData.content.html}</RichText>
            </div>
          )}
          <div className={styles.buttons}>
            {items?.map(items =>
              items.cta_link.url && items.cta_link.url.includes('typeform.com') ? (
                <PopupButton id={items.cta_link.url.substring(items.cta_link.url.lastIndexOf('/') + 1)} className={`${buttonStyles.button}`} key={uuidv4()}>
                  {items.cta_title}
                </PopupButton>
              ) : (
                <a href={items.cta_link.url} key={uuidv4()}>
                  <Button>{items.cta_title}</Button>
                </a>
              )
            )}
          </div>
        </div>
        {width <= 1200 && width > 768 && (
          <div className={styles.content}>
            <RichText>{primaryData.content.html}</RichText>
          </div>
        )}
      </div>
      <div
        className={styles.imageContainer}
        style={{
          order: primaryData.image_position === 'Right' ? 2 : 1,
          backgroundImage: primaryData.image.url ? `url("${width > 576 ? primaryData.image.url : primaryData.image_mobile.url ? primaryData.image_mobile.url : primaryData.image.url}")` : 'none',
        }}
      >
        {!primaryData.image.url && primaryData.video_url.url && (
          <div
            style={width < 768
              ? { height: container?.current?.offsetHeight - contentBlock?.current?.offsetHeight || 0 }
              : { height: '100%' }}
          >
            <Video
              url={primaryData.video_url.url}
              url_mobile={primaryData.video_mobile_url?.url}
              autoPlay={primaryData.video_autoplay}
              muted={primaryData.video_muted}
              loop={primaryData.video_loop}
              playsInline={primaryData.video_playsinline}
              controls={primaryData.video_controls}
            />
          </div>
        )}
      </div>
    </div>
  )
};

export default TwoColumnImage;