import React from 'react';
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import * as styles from './two-column-content.module.scss';
import Button from "../../common/button";
import RichText from "../../common/rich-text";
import { v4 as uuidv4 } from 'uuid';
import {PopupButton} from "@typeform/embed-react";
import * as buttonStyles from "../../common/button/button.module.scss";

const TwoColumnContent = ({ slice }) => {
  const primaryData = slice.primary;
  const items = slice.items;

  const { width } = useWindowSize();

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <h3 className={styles.subheading}>
          <RichText>{primaryData.subheading.html}</RichText>
        </h3>
        <h2 className={styles.heading}>
          {primaryData.heading.text}
        </h2>
        {width <= 768 && (
          <p className={styles.content}>
            <RichText>{primaryData.content.html}</RichText>
          </p>
        )}
        <div className={styles.buttons}>
          {items?.map(item =>
            item.cta_link.url && item.cta_link.url.includes('typeform.com') ? (
              <PopupButton id={item.cta_link.url.substring(item.cta_link.url.lastIndexOf('/') + 1)} className={`${buttonStyles.button}`} key={uuidv4()}>
                {items.cta_title}
              </PopupButton>
            ) : (
              <a href={item.cta_link.url} key={uuidv4()}>
                <Button>{item.cta_title}</Button>
              </a>
            )
          )}
        </div>
      </div>
      {width > 768 && (
        <p className={styles.content}>
          <RichText>{primaryData.content.html}</RichText>
        </p>
      )}
    </div>
  )
};

export default TwoColumnContent;