// extracted by mini-css-extract-plugin
export var container = "two-column-image-small-module--container--12p2p";
export var imageContainer = "two-column-image-small-module--imageContainer--VVKDR";
export var contentBlock = "two-column-image-small-module--contentBlock--s2Y01";
export var contentWrapper = "two-column-image-small-module--contentWrapper--7h0yv";
export var heading = "two-column-image-small-module--heading--nRwIf";
export var subheading = "two-column-image-small-module--subheading--ox4dV";
export var content = "two-column-image-small-module--content--P20+b";
export var buttons = "two-column-image-small-module--buttons--i1j2t";
export var gridImageLeft = "two-column-image-small-module--gridImageLeft--dc2MI";
export var gridContentRight = "two-column-image-small-module--gridContentRight--doCN7";
export var gridImageRight = "two-column-image-small-module--gridImageRight--7sspW";
export var gridContentLeft = "two-column-image-small-module--gridContentLeft--gBJZj";