import React from 'react';
import * as styles from './button.module.scss';

const Button = ({ children, useColorTheme = false, ...props }) => {
  return (
    <button className={`${styles.button} ${useColorTheme && 'theme-button'}`} {...props}>
      {children}
    </button>
  );
};

export default Button;