import React, { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react";
import {  Autoplay, EffectFade } from "swiper";
import { v4 } from 'uuid';
import 'swiper/css';
import "swiper/css/effect-fade";
import "swiper/css/pagination";
import * as styles from './gallery-slider.module.scss';
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import Video from "../../common/video";

const GallerySlider = ({ slice }) => {
  const items = slice.items;
  const primaryData = slice.primary;
  const [activeIndex, setActiveIndex] = useState(0);
  const [swiperInstance, setSwiperInstance] = useState(null)

  const { width } = useWindowSize();

  return (
    <div style={{backgroundColor: 'black', width: '100%', padding: '82px 0'}}>
      <div className={styles.wrapper}>
        <Swiper
          spaceBetween={30}
          effect={"fade"}
          autoplay={{
            delay: 4500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, EffectFade]}
          onSlideChange={({activeIndex}) => setActiveIndex(activeIndex)}
          onSwiper={(swiper) => setSwiperInstance(swiper)}
        >
          {items.map((item) => (
            <SwiperSlide key={v4()}>
              <div className={styles.slide}>
                {item.image.url ? (
                    <img
                      src={width > 576 ? item.image.url : item.image_mobile.url ? item.image_mobile.url : item.image.url}
                      alt={'section'}
                    />
                  )
                  : item.video_url.url && (
                  <Video
                    url={ item.video_url.url}
                    url_mobile={item.video_mobile_url?.url}
                    autoPlay={item.video_autoplay}
                    muted={item.video_muted}
                    loop={item.video_loop}
                    playsInline={item.video_playsinline}
                    controls={item.video_controls}
                  />
                )}
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <div className={styles.content}>
          <div className={styles.pagination}>
            {items.map((item, index) =>
              <div
                onClick={() => swiperInstance.slideTo(index)}
                key={index}
                className={styles.paginationDots}
              />
            )}
            <div
              className={styles.paginationActiveDot}
              style={{left: 22 * activeIndex}}
            />
          </div>
          <h4 className={styles.heading}>
            {primaryData.title1.text}
          </h4>
          <p className={styles.description}>
            {primaryData.description.text}
          </p>
        </div>
      </div>
    </div>
  );
};

export default GallerySlider;