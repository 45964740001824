import React from 'react';
import PropTypes from 'prop-types';
import FullPageCta from '../full-page/full-page-cta';
import Features from "../full-page/features";
import Slider from "../full-page/slider";
import GallerySlider from "../full-page/gallery-slider";
import FullWidthImage from "../full-page/full-width-image";
import TwoColumnImage from "../full-page/two-column-image";
import TwoColumnImageSmall from "../full-page/two-column-image-small";
import TwoColumnContent from "../full-page/two-column-content";


export const SliceZones = ({ slices, fullpage }) => {
  if (slices === undefined) {
    return null;
  }

  const sliceComponents = {
    fullpage_cta: FullPageCta,
    fullpage_features: Features,
    image_gallery_1: Slider,
    image_gallery_2: GallerySlider,
    fullpage_image: FullWidthImage,
    fullpage_2_columns: TwoColumnImage,
    '2_columns': TwoColumnImageSmall,
    '2_columns1': TwoColumnContent,
  }

  return slices.map((slice, index) => {
    const SliceComponent = sliceComponents[slice.slice_type]

    if (SliceComponent) {
      return fullpage
        ? (
          <section className="section" key={`slice-${slice.id}`}>
            <SliceComponent slice={slice} />
          </section>
        ) : (
          <SliceComponent slice={slice} key={`slice-${slice.id}`} />
        )
    }

    return null
  })
}

SliceZones.propTypes = {
  fullpage: PropTypes.bool,
}

SliceZones.defaultProps = {
  fullpage: false,
}