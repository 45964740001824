import React from 'react';
import * as styles from "./features.module.scss";
import Button from "../../common/button";
import RichText from "../../common/rich-text";
import { v4 as uuidv4 }  from 'uuid';
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import Font from "../font";
import Video from "../../common/video";
import {PopupButton} from "@typeform/embed-react";

const Features = ({ slice }) => {
  const { height } = useWindowSize();
  const primaryData = slice.primary;
  const items = slice.items;
 
  return (
    <div
      className={`full-page-section themed ${styles.bg} ${primaryData.theme ? primaryData.theme : ''}`}
      style={{
        height: height,
      }}
    >
    {!primaryData.image.url && primaryData.video_url.url ? (
      <div
        style={{ 
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          zIndex: -1,
          height: '100%',
          width: '-webkit-fill-available',
        }}
      >
        <Video
          url={primaryData.video_url.url}
          url_mobile={primaryData.video_mobile_url?.url}
          autoPlay={primaryData.video_autoplay}
          muted={primaryData.video_muted}
          loop={primaryData.video_loop}
          playsInline={primaryData.video_playsinline}
          controls={primaryData.video_controls}
        />
      </div>
    ) : (
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1,
          height: '100%',
          width: '-webkit-fill-available',
        }}
      >
        <picture>
          <source media="(max-width: 575px)" srcSet={primaryData.image_mobile.url } />
          <source media="(min-width: 576px)" srcSet={primaryData.image.url} />
          <img src={primaryData.image.url} style={{width: "100%", height: "100%", objectFit: "cover"}} alt={"full page bg image"}/>
        </picture>
      </div>
    )}
      <div className={styles.content}>
        <h1 className={`${styles.heading} theme-title`}>
          {primaryData.heading.text}
        </h1>
        <h2 className={`${styles.subheading} theme-title`}>
          <RichText>{primaryData.subheading.html}</RichText>
        </h2>
        <div className={styles.featureContainer}>
          <div className={styles.features}>
            {items.map((feature, i) =>
              <div className={`${styles.feature} theme-title`} key={`feature-${ uuidv4() }`}>
                {feature.image?.url && <img className={`${styles.pic} theme-title`} src={feature.image.url} alt="icon" />}
                {feature.title1.text && <p className={`${styles.title} theme-title`}>{feature.title1.text}</p>}
                <p className={`${styles.subtitle} theme-title`}>{feature.subtitle.text}</p>
              </div>
            )}

            {primaryData.cta_title && (
              primaryData.cta_link.url && primaryData.cta_link.url.includes('typeform.com') ? (
                <PopupButton id={primaryData.cta_link.url.substring(primaryData.cta_link.url.lastIndexOf('/') + 1)}>
                  {primaryData.cta_title}
                </PopupButton>
              ) : (
                <a href={primaryData.cta_link.url}>
                  <Button useColorTheme>
                    {primaryData.cta_title}
                  </Button>
                </a>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;