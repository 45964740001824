import React from 'react';
import * as styles from './full-width-image.module.scss';
import { useWindowSize } from "../../../utils/hooks/useWindowSize";
import Video from "../../common/video";

const FullWidthImage = ({ slice }) => {
  const { width, height } = useWindowSize();
  const primaryData = slice.primary;

  return (
    <div
      className={`full-page-section ${styles.container}`}
      style={{ height: height }}
    >
      {primaryData.image.url ? (
          <img
            src={width > 576 ? primaryData.image.url : primaryData.image_mobile.url ? primaryData.image_mobile.url : primaryData.image.url}
            alt={'Clarity Coat'}
          />
        )
        : primaryData.video_url.url && (
        <Video
          url={ primaryData.video_url.url}
          url_mobile={primaryData.video_mobile_url?.url}
          autoPlay={primaryData.video_autoplay}
          muted={primaryData.video_muted}
          loop={primaryData.video_loop}
          playsInline={primaryData.video_playsinline}
          controls={primaryData.video_controls}
        />
      )}
    </div>
  )
};

export default FullWidthImage;